import { Component, OnInit, AfterViewInit } from '@angular/core';
import { environment } from '@env/environment';
import { QuoteService } from '../home/quote.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs';
declare var $: any;
declare var Intercom: any;

@Component({
  selector: 'app-quotes',
  templateUrl: './quotes.component.html'
})
export class QuotesComponent implements OnInit, AfterViewInit {
  version: string = environment.version;
  assets_root = environment.domain_folder;
  autoquote_url = environment.autoquote_url;
  user: any = { email: '' };
  quoteListModel = {
    pending: {
      list: ['pending', 'declined'],
      title: 'Pending Quote(s)',
      status: ['all status', 'incomplete', 'awaiting', 'pending'],
      emptyMessage: { mainMessage: 'No Pending Estimates Found', subMessage: 'You have no quote pending approval.' }
    },
    history: {
      list: ['history'],
      title: 'Project History',
      status: ['all status', 'ongoing', 'completed', 'cancelled'],
      emptyMessage: { mainMessage: 'Project History Empty', subMessage: 'You have no projects' }
    },
    declined: {
      list: ['declined'],
      title: 'Declined Quote(s)',
      status: ['all status', 'declined'],
      emptyMessage: { mainMessage: 'No Declined Estimates Found', subMessage: 'You have no declined quote.' }
    }
  };
  buttonText = {
    incomplete: 'Resume Quote',
    awaiting: 'View Request',
    pending: 'Pay Now',
    declined: 'Renegotiate',
    ongoing: 'View Detail',
    completed: 'View Detail',
    cancelled: 'View Detail'
  };
  lists: any[] = [];
  projectTypes: any[] = [
    { name: 'translation', value: 'Translation', checked: false },
    { name: 'interpretation', value: 'Interpretation', checked: false },
    { name: 'transcription', value: 'Transcription', checked: false },
    { name: 'localization', value: 'Localization', checked: false },
    { name: 'immigration', value: 'Immigration', checked: false },
    { name: 'academic-evaluation ', value: 'Academic Evaluation ', checked: false },
    { name: 'certified-translation', value: 'Certified Translation', checked: false },
    { name: 'web-service', value: 'Web Service', checked: false },
    { name: 'others', value: 'Others', checked: false }
  ];
  languages: any[] = [];
  languageFilter: any[] = [];
  projectTypeFilter: any[] = [];
  filterApplied = false;
  statusType: string = '';
  queryType: string = '';
  params$: Subscription;
  filter_status: any = { options: ['all status', 'ongoing', 'completed'] };
  quotes: any = [];
  show_history: string = 'Ongoing';
  sub: any;
  no_result = true;
  emailresp: any = { quotes: [] };
  history_data: any = {};
  eml: any = '';
  error_response: any = '';
  deviceInfo: any = null;
  whats_link: any = '';
  show_loader: boolean = false;
  total_item: number = 0;
  filter_array: string[] = [];
  start_date: string = '';
  end_date: string = '';
  date_filter = '';
  status_filter = 'all status';
  listWithoutFilter: any[] = [];

  constructor(
    private quoteService: QuoteService,
    private route: Router,
    private routes: ActivatedRoute,
    private deviceService: DeviceDetectorService,
    public datepipe: DatePipe
  ) {}
  ngOnInit() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    if (isMobile === true || isTablet === true) {
      this.whats_link =
        "whatsapp://send?phone=17182850845&text=Hi, I would like to know more about my translation order. Let's chat!";
    } else {
      this.whats_link =
        'https://web.whatsapp.com/send?phone=17182850845&text=Hi,%20I%20would%20like%20to%20know%20more%20about%20my%20translation%20order.%20Let%27s%20chat!';
    }
    this.params$ = this.routes.params.subscribe(params => {
      this.statusType = params.type;

      this.sub = this.routes.queryParams.subscribe(params => {
        this.eml = params.eml;
        this.queryType = params.type;
        this.loadRecord();
      });
    });
  }
  ngAfterViewInit() {
    this.status_filter = 'all status';
  }
  loadRecord() {
    this.lists = [];
    this.quotes = [];
    this.quoteListModel[this.statusType].list.forEach((data: any) => {
      const record = this.quoteListModel[data];
      this.lists.push({ title: record.title, data: [], type: data });
    });
    //Get History here
    if (this.eml != '') {
      this.show_loader = true;
      this.quoteService.getHistory(this.eml).subscribe(
        (res: any) => {
          if (res.status == '200') {
            this.lists.forEach((list: any, index: number) => {
              list['page_' + index] = 1;
              res.data.forEach((data: any) => {
                if (this.quoteListModel[list.type].status.indexOf(data.filterstatus) != -1) {
                  list.data.push(data);
                  if (index === 0) {
                    this.quotes.push(data);
                  }
                }
              });
            });
            this.applyFilter();
            this.show_loader = false;
          } else {
            this.show_loader = false;
          }
        },
        err => {}
      );
    }
  }

  openDetailPage(quote: any) {
    if (quote.filterstatus === 'incomplete') {
      window.open(this.autoquote_url + '/incomplete/' + quote.type + '/' + quote.id, '_blank');
    } else if (quote.filterstatus === 'declined') {
      this.route.navigate(['renegotiate/'], { queryParams: { id: quote.id, type: quote.type } });
    } else {
      window.location.href = 'quote?id=' + quote.id + '&type=' + quote.type;
    }
  }

  applyFilter() {
    this.filterApplied = true;
    this.languageFilter = [];
    this.languages.forEach(language => {
      if (language.checked) {
        this.languageFilter.push(language.name);
      }
    });
    this.projectTypeFilter = [];
    this.projectTypes.forEach(projectType => {
      if (projectType.checked && projectType.value != '') {
        this.projectTypeFilter.push(projectType.value.toLowerCase());
      }
    });
    let filteredData: any[] = this.quotes;
    if (this.languageFilter.length > 0) {
      filteredData = filteredData.filter((list: any) => {
        return (
          this.languageFilter.indexOf(list.sprj1_source_lang_c) != -1 ||
          this.languageFilter.indexOf(list.sprj1_target_lang_c) != -1
        );
      });
    }
    if (this.projectTypeFilter.length > 0) {
      filteredData = filteredData.filter((list: any) => {
        return this.projectTypeFilter.indexOf(list.sprj1_service_c.toLowerCase()) != -1;
      });
    }
    if (this.status_filter != '' && this.status_filter != 'all status') {
      filteredData = filteredData.filter((list: any) => {
        return list.filterstatus.toLowerCase() === this.status_filter.toLowerCase();
      });
    }
    if (this.date_filter != '') {
      if (this.date_filter.length > 0) {
        this.start_date = this.datepipe.transform(this.date_filter[0], 'yyyy-MM-dd');
        this.end_date = this.datepipe.transform(this.date_filter[1], 'yyyy-MM-dd');
        filteredData = filteredData.filter((list: any) => {
          return this.start_date <= list.date_entered && this.end_date >= list.date_entered;
        });
      }
    }
    this.lists[0].data = filteredData;
  }
  submitEmail() {
    this.eml = this.user.email;
    this.loadRecord();
  }
  clearFilter() {
    this.start_date = '';
    this.end_date = '';
    this.date_filter = '';
    this.status_filter = 'all';
    this.projectTypeFilter = [];
    this.languageFilter = [];
    this.applyFilter();
  }
  ngOnDestroy() {
    this.params$.unsubscribe();
    this.sub.unsubscribe();
  }
}
