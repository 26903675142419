import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { QuoteService } from '../home/quote.service';
@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.scss']
})
export class ThankYouComponent implements OnInit, AfterViewInit {
  status: number = 0;
  pageDetail = { 1: 'new manual quote submited', 2: 'Request for change', 4: 'renegotiation' };
  show_loader: boolean = true;
  params$: Subscription;
  quote = { id: '', type: '' };
  name: string = '';
  referenceId: string = '';
  project_type: string = '';
  sourceLanguage: any = [];
  targetLanguage: any = [];
  tempTargetLanguage: any = [];
  email: string = '';
  filterstatus: string = '';
  showFiles: boolean = false;
  quoteFiles: any = [];
  isAutoquote: boolean = false;
  constructor(private route: Router, private routes: ActivatedRoute, private quoteService: QuoteService) {}

  ngOnInit() {}

  ngAfterViewInit() {
    setTimeout(function() {
      scrollTo(0, -1);
    }, 0);
    this.params$ = this.routes.queryParams.subscribe(params => {
      this.status = params['d'];
      if (params.id && params.type) {
        this.quote.id = params.id;
        this.quote.type = params.type;
        this.show_loader = true;
        this.quoteService.getOppLeadDetailById(params.id, params.type).subscribe((res: any) => {
          this.show_loader = false;
          if (res != null && res.status == '200') {
            this.referenceId = res.data.redux.referenceId;
            this.project_type = res.data.redux.project_type;
            this.sourceLanguage = res.data.redux.source_language;
            this.targetLanguage = res.data.redux.target_language;
            // this.targetLanguage = this.splitArrayIntoChunksOfLen(this.tempTargetLanguage, 2);
            this.email = res.data.redux.email;
            this.filterstatus = res.data.redux.filterstatus;
            this.isAutoquote = res.data.redux.isAutoquote;
            this.syncFilesFromCrm();
          } else {
            this.route.navigate(['/']);
          }
        });
      }
    });
  }
  splitArrayIntoChunksOfLen(arr: [], len: number) {
    var chunks = [],
      i = 0,
      n = arr.length;
    while (i < n) {
      chunks.push(arr.slice(i, (i += len)));
    }
    return chunks;
  }
  syncFilesFromCrm() {
    this.quoteService
      .getQuoteAllFiles({
        crm_quote_type: this.quote.type,
        crm_id: this.quote.id,
        isCertified: false
      })
      .subscribe(response => {
        if (response.status == 200) {
          this.quoteFiles = response.data;
        }
      });
  }
  backToQuoteView() {
    this.route.navigate(['autoquote'], { queryParams: { id: this.quote.id, type: this.quote.type } });
    return false;
  }
  backToPendingList() {
    window.location.href = 'quotes/pending?eml=' + this.email;
  }
  ngOnDestroy() {
    this.params$.unsubscribe();
  }
}
