import { Component, OnInit, OnDestroy } from '@angular/core';
import { environment } from '@env/environment';
import { QuoteService } from './quote.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  quote: string;
  user: any = { email: '' };
  assets_root = environment.domain_folder;
  isLoading: boolean;
  sub: any;
  eml: any;

  constructor(private quoteService: QuoteService, private route: Router, private routes: ActivatedRoute) {}

  ngOnInit() {
    this.sub = this.routes.queryParams.subscribe(params => {
      if (params['id'] != '' && params['id'] != null && params['type'] != '') {
        this.route.navigate(['quote'], { queryParams: { id: params['id'], type: params['type'] } });
      }
      this.eml = params.eml;
    });
    if (this.eml !== undefined) {
      this.route.navigate(['quotes/pending'], { queryParams: { eml: this.eml } });
    }
    this.isLoading = true;
  }

  submitEmail() {
    this.route.navigate(['quotes/pending'], { queryParams: { eml: this.user.email } });
  }

  ngOnDestroy() {
    if (this.eml) {
      this.sub.unsubscribe();
      this.eml = '';
    }
  }
}
