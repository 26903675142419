import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '@app/core';
import { Shell } from '@app/shell/shell.service';
import { QuotesComponent } from './quotes.component';
import { IncompleteStepComponent } from './incomplete-step/incomplete-step.component';

const routes: Routes = [
  Shell.childRoutes([
    { path: 'quotes/:type', component: QuotesComponent, data: { title: extract('Quotes') } },
    {
      path: 'quotes/incomplete/:type/:id',
      component: IncompleteStepComponent,
      data: { title: extract('Incomplete Quote') }
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class QuotesRoutingModule {}
