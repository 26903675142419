import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { environment } from '@env/environment';
import { QuoteService } from '../home/quote.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DatePipe } from '@angular/common';
import { Store } from '@ngrx/store';
import { Quote } from './../models/quote.model';
import { AppState } from './../app.state';
import * as QuoteActions from './../actions/quote.actions';

declare var $: any;
declare var braintree: any;
declare var Date: any;
declare var BuyWithCrypto: any;

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit, AfterViewInit, OnDestroy {
  autoquote: Quote = <Quote>{};
  isSyncDone: boolean = false;
  deviceInfo: any = null;
  assets_root = environment.domain_folder;
  fractionSize: any;
  whats_link: any = '';
  phone_error: any;
  month_error = '';
  year_error = '';
  amount: any;
  email: any;
  coinbaseStep: number = 1;
  show_feedback_form = true;
  success_message: any = false;
  success_message_desc: string = '';
  show_notification_form = false;
  feedback_error_message: string = '';
  success_noti_message_desc: string = '';
  notification_error_message: string = '';
  type: any = '';
  url_type: any = '';
  error_message: string = '';
  show_loader = false;
  sub: any;
  id: any;
  payment_type: any = 'credit_card';
  additonal_submitted_id: any = '';
  ShippingAddress: any = '';
  applicable_object: any = '';
  form_submitted = false;
  add_comment = false;
  notification_select: any = {
    options: [{ value: 'WhatsApp', name: 'WhatsApp' }, { value: 'SMS', name: 'SMS' }]
  };
  howToKnowOptions: any = {
    options: [
      { value: "I'm a returning customer", name: "I'm a returning customer" },
      { value: 'Google', name: 'Google' },
      { value: 'Yahoo/Bing/Others', name: 'Yahoo/Bing/Others' },
      { value: 'Social Media/YouTube/Linkedin', name: 'Social Media/YouTube/Linkedin' },
      { value: 'Referred by friend/collague', name: 'Referred by friend/collague' }
    ]
  };
  notification: any = {
    crm_id: '',
    crm_type: '',
    mode: 'WhatsApp',
    phone_number: ''
  };
  paymentDetail: any = {
    ccname: '',
    ccno: '',
    expMo: '',
    expYr: '',
    ccvv: '',
    crm_type: 'op',
    selector: 'approved',
    is_billing: true,
    billing_type: 'credit_card',
    payment_method_nonce: '',
    crm_user_id: '',
    billing_address_invoice: '',
    country_invoice: '',
    zip_code_invoice: '',
    city_invoice: '',
    state_invoice: ''
  };
  feedback: any = {
    rating: 0,
    comment: '',
    crm_user_id: '',
    crm_user_name: '',
    howToKnowAboutDT: ''
  };
  total_amount = 0;
  amount_paid = 0;
  countryCode = '+1';
  showCryptoBusyPage: boolean = false;
  constructor(
    private quoteService: QuoteService,
    private route: Router,
    private routes: ActivatedRoute,
    private deviceService: DeviceDetectorService,
    public datepipe: DatePipe,
    private store: Store<AppState>
  ) {}

  ngOnInit() {
    this.store.select('quote').subscribe(data => {
      this.autoquote = data[0];
    });
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktopDevice = this.deviceService.isDesktop();
    if (isMobile === true) {
      this.whats_link =
        "whatsapp://send?phone=17182850845&text=Hi, I would like to know more about my translation order. Let's chat!";
    } else if (isTablet === true) {
      this.whats_link =
        "whatsapp://send?phone=17182850845&text=Hi, I would like to know more about my translation order. Let's chat!";
    } else if (isDesktopDevice === true) {
      this.whats_link =
        'https://web.whatsapp.com/send?phone=17182850845&text=Hi,%20I%20would%20like%20to%20know%20more%20about%20my%20translation%20order.%20Let%27s%20chat!';
    } else {
      this.whats_link =
        'https://web.whatsapp.com/send?phone=17182850845&text=Hi,%20I%20would%20like%20to%20know%20more%20about%20my%20translation%20order.%20Let%27s%20chat!';
    }
    this.sub = this.routes.queryParams.subscribe(params => {
      this.id = params.id;
      this.url_type = params.type;
      if (this.url_type != 'op' && this.url_type != 'ld') {
        this.show_loader = false;
        this.route.navigate(['/quote'], { queryParams: { id: this.id, type: this.url_type } });
      } else {
        this.syncFormData();
      }
    });
  }
  checkKey(event: any) {
    if (event.keyCode === 13) {
      return false;
    }
  }
  coinbaseCheckoutId: string = '';
  isPaymentProcced: boolean = false;
  syncFormData() {
    this.quoteService.getOppLeadDetailById(this.id, this.url_type).subscribe((res: any) => {
      if (res != null && res.status == '200') {
        if (!res.data.redux.is_coinbase_inprogress) {
          this.isSyncDone = true;
          Object.keys(res.data.redux).forEach((val: any, index: any) => {
            if ('due_date' == val && res.data.redux[val] != '') {
              this.autoquote[val] = new Date(res.data.redux[val]);
            } else {
              this.autoquote[val] = res.data.redux[val];
            }
          });
          this.total_amount = res.data.redux.total_amount_sum + this.autoquote.totalSideBarAmount;
          this.amount_paid = res.data.redux.amount_paid;
          this.amount = res.data.redux.amount_pending + this.autoquote.totalSideBarAmount;
          this.email = res.data.redux.email;
          this.quoteService
            .generateCheckoutId({ a: this.autoquote.totalSideBarAmount, id: this.id, type: this.url_type })
            .subscribe(res => {
              if (res.status === 200) {
                this.coinbaseCheckoutId = res.response.checkoutId;
              }
            });
          this.store.dispatch(new QuoteActions.AddUpdateQuote(this.autoquote));
          if (this.amount == 0) {
            this.route.navigate(['/quote'], { queryParams: { id: this.id, type: this.url_type } });
          } else {
            this.show_loader = false;
          }
        } else {
          this.route.navigate(['/quote'], { queryParams: { id: this.id, type: this.url_type } });
        }
      } else {
        this.route.navigate(['/']);
      }
    });
  }

  ngAfterViewInit() {
    const self = this;
    setTimeout(function() {
      scrollTo(0, -1);
    }, 0);
    var folder_path = environment.domain_folder;
    var api_path = environment.api_url;
    $.getScript('https://js.braintreegateway.com/v2/braintree.js');
    $.getScript(folder_path + '/assets/js/jquery.js', function() {
      $(document).on('click', '#braintree-paypal-button', function() {
        $('#paypal_submit_btn').removeAttr('disabled');
        $('#login_text_paypal').remove();
      });

      (function() {
        BuyWithCrypto.registerCallback('onSuccess', (e: any) => {
          // Charge was successfully completed
          console.log('onSuccess', e);
          self.quoteService.submitLogToServer(e).subscribe((data: any) => {});
        });

        BuyWithCrypto.registerCallback('onFailure', (e: any) => {
          // Charge failed
          console.log('onFailure', e);
          self.showCryptoBusyPage = false;
          self.quoteService.submitLogToServer(e).subscribe((data: any) => {});
        });

        BuyWithCrypto.registerCallback('onPaymentDetected', (e: any) => {
          // Payment has been detected but not yet confirmed
          console.log('onPaymentDetected', e);

          self.quoteService.submitLogToServer(e).subscribe((data: any) => {});
        });
        var BTFn: any = {};

        BTFn.sendJSON = function() {
          $.ajax({
            dataType: 'text',
            type: 'POST',
            data: {},
            url: api_path + '/approvals/getAccessToken',
            success: function(req: any) {
              BTFn.initBT(req);
            },
            error: function() {}
          });
        };

        BTFn.initBT = function(req: any, $pay_btn: any) {
          braintree.setup(req, 'paypal', {
            container: 'paypal-button',
            onReady: function() {
              $('.loader_container').remove();
            },
            onError: function(error: any) {
              //$pay_btn.show().closest('.btn_container').find('.loader_img').hide();
            }
          });
        };

        BTFn.formValidate = function($form: any, $submit: any, $amount: any, $pay_btn: any) {
          var THIS = this;

          $submit.on('click', function(e: any) {
            $('.input-label .invalid-bottom-bar').removeClass('invalid');
            $(this)
              .hide()
              .closest('.btn_container')
              .find('.loader_img')
              .css('display', 'inline-block');
          });
        };

        BTFn.updateForm = function($form: any, link: any) {
          $form.attr('action', link);
          $('.one_off_amount, .monthly_amount').toggleClass('hide');
        };

        BTFn.appendTo = function($cont: any, childSelector: any, options: any) {
          var input = document.createElement(childSelector);
          input.type = options.type;
          input.name = options.name;
          input.value = options.value;
          $cont.appendChild(input);
        };

        $('#v-pills-profile-tab').click(function() {
          $('#paypal_submit_btn').attr('disabled', 'disabled');
          $('.loader_container')
            .find('div')
            .show();
          BTFn.sendJSON();
        });
      })();
    });
  }

  removeMsg(msg_type: any) {
    if (msg_type == 'success_message_desc') {
      this.success_message_desc = '';
    }
    if (msg_type == 'feedback_error_message') {
      this.feedback_error_message = '';
    }
    if (msg_type == 'success_noti_message_desc') {
      this.success_noti_message_desc = '';
    }
    if (msg_type == 'notification_error_message') {
      this.notification_error_message = '';
    }
  }
  backToHome() {
    this.route.navigate(['/']);
  }
  backToCart() {
    this.route.navigate(['quotes/pending'], { queryParams: { eml: this.autoquote.email } });
  }
  backToQuotePage() {
    this.route.navigate(['autoquote/'], { queryParams: { id: this.id, type: this.url_type } });
    return false;
  }

  hasError(event: any) {
    if (event === true) {
      this.phone_error = true;
    } else {
      this.phone_error = false;
    }
  }

  telInputObject(event: any) {}
  getNumber(event: any) {}

  submitCall() {
    var hidden_val = document.getElementsByName('payment_method_nonce')[0];
    var valueField = $(hidden_val).val();
  }

  onCountryChange($event: any) {
    this.countryCode = '+' + $event.dialCode;
  }
  notificationSubmit() {
    if (!this.phone_error) {
      return false;
    }
    if (this.notification.phone_number.indexOf('+') === -1) {
      this.notification.phone_number = this.countryCode + this.notification.phone_number;
    }
    if (this.id != '') {
      this.notification.crm_id = this.id;
    }
    if (this.url_type != '') {
      this.notification.crm_type = this.url_type;
    }
    this.show_loader = true;
    this.quoteService.updateNotificationSetting(this.notification).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.show_loader = false;
          this.success_message = true;
          this.show_notification_form = false;
          this.success_noti_message_desc = res.message;
        } else {
          this.show_loader = false;
          this.success_message = true;
          this.show_notification_form = true;
          this.notification_error_message = res.message;
        }
      },
      err => {}
    );
  }

  addComment() {
    if (this.add_comment === false) {
      this.add_comment = true;
    } else {
      this.add_comment = false;
    }
  }

  submitFeedback() {
    if (this.id != '') {
      this.feedback.crm_user_id = this.id;
    }
    if (this.url_type != '') {
      this.feedback.crm_user_name = this.url_type;
    }
    this.show_loader = true;
    this.quoteService.addFeedback(this.feedback).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.show_loader = false;
          this.success_message = true;
          this.show_feedback_form = false;
          this.success_message_desc = res.message;
        } else {
          this.show_loader = false;
          this.success_message = true;
          this.show_feedback_form = true;
          this.feedback_error_message = res.message;
        }
      },
      err => {}
    );
  }

  expirationCheck() {
    if (this.paymentDetail.expMo != '') {
      var str = this.paymentDetail.expMo;
      var result = str.split(' / ');
      if (result[0] !== undefined) {
        if (result[0].length == 2) {
          if (result[0] < this.datepipe.transform(new Date(), 'MM')) {
            this.month_error = 'Please select valid month';
          } else if (result[0] > 12) {
            this.month_error = 'Please select valid month';
          } else {
            this.month_error = '';
          }
        } else {
          this.month_error = 'Please select valid month';
        }
      }

      if (result[1] !== undefined) {
        if (result[1].length == 4) {
          if (result[1] < this.datepipe.transform(new Date(), 'yyyy')) {
            this.year_error = 'Please select valid year';
          } else {
            if (result[1] > this.datepipe.transform(new Date(), 'yyyy') && result[0] <= 12) {
              this.month_error = '';
            }
            this.year_error = '';
          }
        }
        if (result[1].length == 2) {
          if (result[1] < this.datepipe.transform(new Date(), 'yy')) {
            this.year_error = 'Please select valid year';
          } else {
            if (result[1] > this.datepipe.transform(new Date(), 'yy') && result[0] <= 12) {
              this.month_error = '';
            }
            this.year_error = '';
          }
        }
      }
    }
  }

  paymentProcess() {
    var hidden_val = document.getElementsByName('payment_method_nonce')[0];
    var valueField = $(hidden_val).val();
    if (this.paymentDetail.billing_type == 'paypal' && (valueField == '' || valueField === undefined)) {
      this.error_message = 'Please login with your paypal account to proceed.';
      return false;
    } else {
      this.error_message = '';
    }
    this.show_loader = true;
    this.form_submitted = true;

    if (valueField != '' && valueField !== undefined) {
      this.paymentDetail.payment_method_nonce = valueField;
    }

    if (this.id != '') {
      this.paymentDetail.crm_user_id = this.id;
    }
    if (this.url_type != '') {
      this.paymentDetail.crm_type = this.url_type;
    }

    if (this.paymentDetail.expMo != '') {
      var str = this.paymentDetail.expMo;
      var result = str.split('/');
      this.paymentDetail.expMo = result[0];
      this.paymentDetail.expYr = result[1];
    }
    if (this.paymentDetail.is_billing === false) {
      this.paymentDetail.is_billing = '0';
    } else {
      this.paymentDetail.is_billing = '1';
    }
    this.paymentDetail.applicable_text = this.autoquote.applicable_text;
    //this.showCryptoBusyPage=true;
    this.quoteService.paymentProcessService(this.paymentDetail, this.autoquote.selectedAdditionalServiceJson).subscribe(
      (res: any) => {
        if (res.status == '200') {
          if (this.payment_type === 'crypto_btc') {
          } else {
            this.success_message = true;
          }
          this.show_loader = false;
        } else {
          this.error_message = '';
          this.show_loader = false;
          this.form_submitted = false;
          this.error_message = res.message;
        }
      },
      err => {}
    );
  }

  paymentMethod(type: any) {
    this.paymentDetail.billing_type = type;
    this.payment_type = type;
    if (this.payment_type === 'crypto_btc') {
      this.coinbaseStep = 2;
      setTimeout(() => {
        BuyWithCrypto.findButtonAndInstall(document.getElementById('initialize-crypto'));
      });
    }
  }

  ngOnDestroy() {
    if (this.id) {
      this.sub.unsubscribe();
      this.id = '';
      this.url_type = '';
    }
  }
}
