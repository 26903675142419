import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QuotesRoutingModule } from './quotes-routing.module';
import { QuotesComponent } from './quotes.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { NgxPaginationModule } from 'ngx-pagination';
import { IncompleteStepComponent } from './incomplete-step/incomplete-step.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DeviceDetectorModule.forRoot(),
    ReactiveFormsModule,
    NgxPaginationModule,
    TranslateModule,
    QuotesRoutingModule,
    BsDatepickerModule.forRoot(),
    PerfectScrollbarModule
  ],
  declarations: [QuotesComponent, IncompleteStepComponent],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    DatePipe
  ]
})
export class QuotesModule {}
