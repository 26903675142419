import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { QuoteService } from '../../home/quote.service';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '@env/environment';

declare var $: any;
declare var Intercom: any;

@Component({
  selector: 'app-renegotiate',
  templateUrl: './renegotiate.component.html',
  styleUrls: ['./renegotiate.component.scss']
})
export class RenegotiateComponent implements OnInit, AfterViewInit, OnDestroy {
  isSubmitted: boolean = false;
  sub: any;
  assets_root = environment.domain_folder;
  id: string = '';
  type: string = '';
  userDetail: any = {};
  check_array: any = [];
  errors: string = '';
  success_message = false;

  renegotiate: any = {
    renegotiate_text: '',
    crm_type: '',
    crm_user_id: ''
  };

  constructor(private quoteService: QuoteService, private route: Router, private routes: ActivatedRoute) {}

  ngOnInit() {
    this.sub = this.routes.queryParams.subscribe(params => {
      this.id = params.id;
      this.type = params.type;
    });
    if (this.id == '' || this.type == '' || this.type === undefined || this.id === undefined) {
      this.route.navigate(['/home']);
    } else {
      this.renegotiate.crm_type = this.type;
      this.renegotiate.crm_user_id = this.id;
      this.quoteService.getUserFullDetail(this.id, this.type).subscribe(
        (res: any) => {
          if (res.status == '200') {
            this.userDetail = this.quoteService.data;
          } else {
            this.route.navigate(['/home']);
          }
        },
        err => {}
      );
    }
  }

  ngAfterViewInit() {
    setTimeout(function() {
      scrollTo(0, -1);
    }, 0);
  }

  sendRenegotiate(renegotiate: any) {
    if (this.id != '') {
      this.renegotiate.renegotiate_text = renegotiate.renegotiate_text;
      this.quoteService.sendRenegotiate(this.renegotiate).subscribe(
        (res: any) => {
          if (res.status == '200') {
            this.route.navigate(['thankyou/'], {
              queryParams: { d: 4, id: this.id, type: this.type }
            });
          } else {
            this.errors = res.message;
          }
        },
        err => {}
      );
    }
  }

  backToQuotePage(email: any) {
    this.route.navigate(['quotes/pending'], { queryParams: { email: email } });
    return false;
  }

  ngOnDestroy() {
    if (this.id) {
      this.sub.unsubscribe();
      this.id = '';
    }
  }
}
