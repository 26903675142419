import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { QuoteService } from '../../home/quote.service';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '@env/environment';

declare var $: any;
declare var Intercom: any;

@Component({
  selector: 'app-decline',
  templateUrl: './decline.component.html',
  styleUrls: ['./decline.component.scss']
})
export class DeclineComponent implements OnInit, AfterViewInit, OnDestroy {
  isSubmitted: boolean = false;
  sub: any;
  chk_improve_one: any;
  chk_improve_two: any;
  assets_root = environment.domain_folder;
  show_description: string = 'no';
  id: string = '';
  check_selected: string = '';
  validationFailed = true;
  improve_text: string = 'yes';
  type: string = '';
  userDetail: any = {};
  check_array: any = [];
  errors: string = '';
  success_message = false;
  options = [
    { name: 'I am not happy with the price', value: 'Price', checked: false },
    { name: 'I am not happy with the terms', value: 'project_terms', checked: false },
    { name: 'I found a better quote elsewhere', value: 'better_quote', checked: false },
    { name: 'I have a different reason', value: 'Others', checked: false }
  ];
  text_how_we_can_improve: any = '';
  text_why_not_approved_checkbox_other: any = '';

  decline: any = {
    why_not_approved_checkbox_other: '',
    how_we_can_improve: '',
    why_not_approved_checkbox: [],
    crm_type: '',
    crm_user_id: ''
  };

  constructor(private quoteService: QuoteService, private route: Router, private routes: ActivatedRoute) {}

  ngOnInit() {
    this.chk_improve_one = true;
    this.sub = this.routes.queryParams.subscribe(params => {
      this.id = params.id;
      this.type = params.type;
    });
    if (this.id == '' || this.type == '' || this.type === undefined || this.id === undefined) {
      this.route.navigate(['/home']);
    } else {
      this.decline.crm_type = this.type;
      this.quoteService.getUserFullDetail(this.id, this.type).subscribe(
        (res: any) => {
          if (res.status == '200') {
            this.userDetail = this.quoteService.data;
          } else {
            this.route.navigate(['/home']);
          }
        },
        err => {}
      );
    }
  }

  ngAfterViewInit() {
    setTimeout(function() {
      scrollTo(0, -1);
    }, 0);
  }

  showHideImprove(selected: any) {
    if (selected == 'yes') {
      this.improve_text = 'yes';
      this.chk_improve_one = true;
      this.chk_improve_two = false;
    } else {
      this.improve_text = 'no';
      this.chk_improve_one = false;
      this.chk_improve_two = true;
    }
  }

  chkBoxCondition(selected_val: any) {
    if (selected_val != false) {
      const index: number = this.check_array.indexOf(selected_val);
      if (index >= 0) {
        this.check_array.splice(index, 1);
      } else {
        this.check_array.push(selected_val);
      }
    }
    if (this.check_array.length > 0) {
      const index_one: number = this.check_array.indexOf('Others');
      if (index_one >= 0) {
        if (this.text_why_not_approved_checkbox_other != '') {
          this.validationFailed = false;
          this.check_selected = '';
        } else {
          this.validationFailed = true;
          this.check_selected = 'Please describe your reason.';
        }
        this.show_description = 'yes';
      } else {
        this.validationFailed = false;
        this.check_selected = '';
        this.show_description = 'no';
      }
    } else {
      this.show_description = 'no';
      this.validationFailed = true;
      this.check_selected = 'Please select at least one condition.';
    }
  }

  sendDecline() {
    if (this.id != '') {
      for (var x in this.options) {
        if (this.options[x].checked === true) {
          this.decline.why_not_approved_checkbox.push(this.options[x].value);
        }
      }

      this.decline.crm_user_id = this.id;
      this.decline.how_we_can_improve = this.text_how_we_can_improve;
      this.decline.why_not_approved_checkbox_other = this.text_why_not_approved_checkbox_other;
      this.quoteService.sendDecline(this.decline).subscribe(
        (res: any) => {
          if (res.status == '200') {
            this.isSubmitted = true;
          } else {
            this.errors = res.message;
          }
        },
        err => {}
      );
    }
  }

  backToQuotePage(email: any) {
    this.route.navigate(['quotes/pending'], { queryParams: { id: this.id, type: this.type } });
    return false;
  }

  ngOnDestroy() {
    if (this.id) {
      this.sub.unsubscribe();
      this.id = '';
    }
  }
}
