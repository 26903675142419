import { QuoteService } from '../../home/quote.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '@env/environment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-incomplete-step',
  templateUrl: './incomplete-step.component.html',
  styleUrls: ['./incomplete-step.component.scss']
})
export class IncompleteStepComponent implements OnInit {
  steps = ['Basic Details', 'Project Details', 'File Upload', 'Additional Services (optional)', 'Payment'];
  stepCompletedCount = 0;
  quote = { id: '', type: '' };
  params$: Subscription;
  show_loader: boolean = false;
  name: string = '';
  assets_root: string = environment.domain_folder;
  constructor(private quoteService: QuoteService, private routes: ActivatedRoute, private route: Router) {}

  ngOnInit() {
    this.params$ = this.routes.params.subscribe(params => {
      if (params.id && params.type) {
        this.quote.id = params.id;
        this.quote.type = params.type;
        this.show_loader = true;
        this.quoteService.getOppLeadDetailById(params.id, params.type).subscribe((res: any) => {
          this.show_loader = false;
          if (res != null && res.status == '200') {
            this.stepCompletedCount = parseInt(res.data.redux.step) - 2;
            this.name = res.data.redux.first_name;
          } else {
            this.route.navigate(['/']);
          }
        });
      }
    });
  }
  openDetailPage() {
    window.open(environment.autoquote_url + '/?id=' + this.quote.id + '&type=' + this.quote.type, '_blank');
  }
  ngOnDestroy() {
    this.params$.unsubscribe();
  }
}
